import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout, LeadershipCard, PageCard, ContactForm } from '../components/common'
import { MetaData } from '../components/common/meta'

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
const AutoplaySlider = withAutoplay(AwesomeSlider);


/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/


const Page = ({ data, location }) => {
    const page = data.ghostPage
    const leadership = data.ourLeadership.edges
    const services = data.ourServices.edges
    const carousel = data.carousel.edges.filter(({ node }) => (
        "tags" in node && node.tags[0].slug === page.slug + "-carousel" 
    ))

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout bodyClass={`page-${page.slug}`} >
                {
                    carousel.length > 0 ? 
                        <div className="site-banner">
                            <AutoplaySlider
                                play={true}
                                cancelOnInteraction={false} // should stop playing on user interaction
                                interval={6000}
                            >
                                {  carousel.map(({ node, i }) => (
                                    <div key={ i } data-src={ node.feature_image }>{ node.feature_image }</div>
                                ))}
                            </AutoplaySlider>
                        </div> 
                    :
                        page.feature_image &&
                            <div className="page-hero-image" style={{
                                    backgroundImage: `url(${page.feature_image})` ,
                            }}></div>
                }

                <div className="container">
                    <article className="content page-content">
                        <h1 className="content-title">{page.title}</h1>

                        {/* The main page content */}
                        <section
                            className="content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: page.html }}
                        />

                        { page.slug === 'our-services' &&
                            <div className="our-services-container">
                                <section className="page-feed">
                                    {services.map(({ node }) => (
                                        // The tag below includes the markup for each post - components/common/PostCard.js
                                        <PageCard key={node.id} page={node} />
                                    ))}
                                </section>
                            </div>
                        }
                    </article>
                </div>

                { page.slug === 'contact-us' && <ContactForm /> }

                { page.slug === 'about-us' &&
                    <div className="leadership-container">
                        <div className="leadership-hero-image-container">
                            <div className="leadership-hero-image-overlay"></div>
                            <div className="leadership-hero-image"></div>
                        </div>
                        <div className="container leadership-content">
                            <h1>Our Leadership</h1>
                            <section className="leadership-feed">
                                {leadership.map(({ node }) => (
                                    // The tag below includes the markup for each post - components/common/PostCard.js
                                    <LeadershipCard key={node.id} page={node} />
                                ))}
                            </section>
                        </div>
                    </div>
                }
            </Layout>
        </>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Page

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage: ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
        carousel: allGhostPost(filter: {tags: {elemMatch: {slug: {regex: "/carousel/"}}}}) {
            edges {
              node {
                id
                slug
                tags {
                  id
                  slug
                }
                feature_image
              }
            }
          }
        ourLeadership: allGhostPost(sort: {order: ASC, fields: published_at}, filter: {tags: {elemMatch: {slug: {eq: "our-leadership"}}}}) {
                edges {
                node {
                    id
                    feature_image
                    excerpt
                    title
                    slug
                    html
                }
            }
        }
        ourServices: allGhostPost(sort: {order: DESC, fields: published_at}, filter: {tags: {elemMatch: {slug: {eq: "our-services"}}}}) {
                edges {
                node {
                    id
                    feature_image
                    excerpt
                    title
                    slug
                    html
                }
            }
        }
    }
`
